import { GetServerSidePropsContext, NextPage } from 'next'
import { QueryClient, dehydrate } from '@tanstack/query-core'
import { buildRedirectServerSideProps } from '../../utils/Helper'
import { createAxiosInstance } from '../../contexts/axios/AxiosContext'
import { environmentConfig } from '../../config/environment/environmentConfig'
import { getProjectMicroSite } from '../../services/project-microsite/ProjectMicrositeService'
import { useRouter } from 'next/router'
import ComponentManager from '../../components/component-manager/ComponentManager'
import Layout from '../../layout/Layout'
import React from 'react'
import useMicrositePageMetadata from '../../hooks/useMicrositePageMetadata'
import useProjectMicroSite, {
    PROJECT_MICRO_SITE_QUERY_KEY
} from '../../hooks/api/project-microsite/useProjectMicrosite'
import useSendFormsBeforeLeavePage from '../../hooks/useSendFormsBeforeLeavePage'

const ProjectPage: NextPage<{ themeValue: string }> = ({ themeValue }) => {
    useSendFormsBeforeLeavePage('project')
    const {
        query: { id: slug }
    } = useRouter()
    const { data, isLoading } = useProjectMicroSite(slug.toString())
    const { seo, contact, header } = useMicrositePageMetadata({ data, slug: slug as string, isForProject: true })

    if (isLoading) {
        return null
    }

    return (
        <Layout
            hideBackgroundColor
            gradient
            shadowedContact
            title={data.name}
            titleImg={header.logoImageUrl}
            externalScripts={data.trackingCodes}
            contact={contact}
            header={header}
            previewVideoUrl={data.previewVideoUrl}
            seo={seo}
            footer={data.footer}
            components={data?.content}
            themeValue={themeValue}
        >
            <ComponentManager
                type='project'
                components={data?.content}
                brokerEmail={contact.email}
                contactBgColor='secondaryBlue-100'
            />
        </Layout>
    )
}

export const getServerSideProps: GetServerSideAndEnvironmentProps = async (context: GetServerSidePropsContext) => {
    const {
        params: { id: projectSlug }
    } = context
    const queryClient = new QueryClient()
    const axiosInstance = createAxiosInstance(environmentConfig.apiUrl)
    try {
        const projectMicroSiteData = await getProjectMicroSite(axiosInstance, projectSlug.toString())
        // INFO:
        // Set project microsite page to react query (SSR)
        const themeValue = projectMicroSiteData.template.colorTemplate
        await queryClient.prefetchQuery([PROJECT_MICRO_SITE_QUERY_KEY, projectSlug], () => {
            return projectMicroSiteData
        })
        return {
            props: {
                dehydratedState: dehydrate(queryClient),
                environmentConfig,
                themeValue
            }
        }
    } catch (error) {
        const redirect = buildRedirectServerSideProps(error)
        if (redirect) {
            return redirect
        }
        return {
            notFound: true
        }
    }
}

export default ProjectPage
