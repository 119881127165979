import { MicroSite } from '../../../services/Microsite.types'
import { getProjectMicroSite } from '../../../services/project-microsite/ProjectMicrositeService'
import { useAxios } from '../../useAxios'
import { useQuery } from '@tanstack/react-query'

export const PROJECT_MICRO_SITE_QUERY_KEY = 'project-micro-site'

const useProjectMicroSite = (slug: string, initialData?: MicroSite) => {
    const axiosInstance = useAxios()

    return useQuery(
        [PROJECT_MICRO_SITE_QUERY_KEY, slug],
        async () => {
            return getProjectMicroSite(axiosInstance, slug)
        },
        { initialData }
    )
}

export default useProjectMicroSite
